import type { ResourcesConfig } from 'aws-amplify';

export const environment = {
  production: true,
  apiUrl: '{{apiUrl}}',
  confluenceBaseTemplateApiUrl: '{{confluenceBaseTemplateApiUrl}}',

  /**
   * Feature Toggling
   */
  featureTogglingMode: 'FEATURE_HUB',
  featureHub: {
    host: '{{featurehubUrl}}',
    apiKey: '{{featurehubToken}}',
    enableLogs: false,
    pollingFrequency: 15_000,
  },

  /**
   * IAM
   */
  ssoProviderName: 'AzureADIonity',
  enableAuthentication: true,
  amplifyConfig: {
    Auth: {
      Cognito: {
        userPoolId: '{{userPoolId}}',
        userPoolClientId: '{{userPoolWebClientId}}',
        loginWith: {
          oauth: {
            domain: '{{userPoolDomain}}',
            scopes: ['openid', 'email', 'profile', 'aws.cognito.signin.user.admin'],
            redirectSignIn: [`${window.location.origin}`],
            redirectSignOut: [`${window.location.origin}`],
            responseType: 'code',
          },
        },
      },
    },
  } satisfies ResourcesConfig,
  permissionMode: 'PRODUCTION',
  grantedPermissionsForDevelopment: [] as string[],

  /**
   * Map settings
   */
  mapApiKey: '{{mapApiKey}}',
  siteAvailability: {
    lowBoundary: '{{siteAvailabilityLowBoundary}}' as unknown as number,
    highBoundary: '{{siteAvailabilityHighBoundary}}' as unknown as number,
  },
  siteUtilisation: {
    lowBoundary: '{{siteUtilisationLowBoundary}}' as unknown as number,
    highBoundary: '{{siteUtilisationHighBoundary}}' as unknown as number,
  },

  /**
   * Monitoring
   */
  maxLogsCount: '{{maxLogsCount}}' as unknown as number,

  /**
   * Development
   */
  oicpSimulatorApiUrl: '{{oicpSimulatorUrl}}',
  ocpiSimulatorApiUrl: '{{ocpiSimulatorUrl}}',
  logStateManagement: '{{logStateManagement}}' as unknown as boolean,
  includeDevArea: '{{includeDevArea}}' as unknown as boolean,

  /**
   * Site images settings
   */
  siteImageMaxSize: '{{siteImageMaxSize}}' as unknown as number,
  siteImageMinWidth: '{{siteImageMinWidth}}' as unknown as number,
  siteImageMinHeight: '{{siteImageMinHeight}}' as unknown as number,

  /**
   * Legacy feature flags -> Todo: remove once FeatureHub is fully applied
   */
  // The following feature flags will be replaced at runtime and then have type boolean
  // See: https://portal.ionity.eu/display/IONIT/Frontend+Feature+Flags for more information
  featureFlags: {
    stationConfiguration: '{{featureFlagStationConfiguration}}' as unknown as boolean,
    stationDiagnostics: '{{featureFlagStationDiagnostics}}' as unknown as boolean,
    poiFlag: '{{featureFlagPoiFlag}}' as unknown as boolean,
    users: '{{featureFlagUsers}}' as unknown as boolean,
    businessPartnerBrands: '{{featureFlagBusinessPartnerBrands}}' as unknown as boolean,
    vat: '{{featureFlagVat}}' as unknown as boolean,
    demoMode: '{{featureFlagDemoMode}}' as unknown as boolean,
    resetComponent: '{{featureFlagResetComponent}}' as unknown as boolean,
    permissions: '{{featureFlagPermissions}}' as unknown as boolean,
    triggerMessage: '{{featureFlagTriggerMessage}}' as unknown as boolean,
    activateLightScene: '{{featureFlagActivateLightScene}}' as unknown as boolean,
    invoiceDetailRecords: '{{featureFlagInvoiceDetailRecords}}' as unknown as boolean,
    invoicingStatus: '{{featureFlagInvoicingStatus}}' as unknown as boolean,
    updateLightScene: '{{featureFlagUpdateLightScene}}' as unknown as boolean,
    authorizationChecks: '{{featureFlagAuthorizationChecks}}' as unknown as boolean,
    disableStationDelete: '{{featureFlagDisableStationDelete}}' as unknown as boolean,
    triggerAssetUpdate: '{{featureFlagTriggerAssetUpdate}}' as unknown as boolean,
    hardwareVersionField: '{{featureFlagHardwareVersionField}}' as unknown as boolean,
    languageSwitcher: '{{featureFlagLanguageSwitcher}}' as unknown as boolean,
    generateQrCode: '{{featureFlagGenerateQrCode}}' as unknown as boolean,
    connectorStatusFetch: '{{featureFlagConnectorStatusFetch}}' as unknown as boolean,
    evDriversUserProfiles: '{{featureFlagEvDriversUserProfiles}}' as unknown as boolean,
    withoutTypeE: '{{featureFlagWithoutTypeE}}' as unknown as boolean,
    multipleTariffElements: '{{featureFlagMultipleTariffElements}}' as unknown as boolean,
  },
};
