import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@ion/env';
import { fetchAuthSession, fetchUserAttributes, getCurrentUser, signInWithRedirect, signOut } from 'aws-amplify/auth';

// 20 mins
const REFRESH_INTERVAL = 20 * 60 * 1000;

/**
 * Authentication service that provides methods for signing in, signing out,
 * and managing user authentication using AWS Cognito.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService implements OnDestroy {
  /**
   * Interval for periodic token refresh.
   */
  private readonly interval = setInterval(() => this.refresh(), REFRESH_INTERVAL);

  /**
   * Last thrown error of AWS Cognito sign-in process, if there was any
   */
  lastAuthError = '';

  /**
   * Sign in a user using federated authentication.
   *
   * @param returnUrl - The return URL to redirect the user after authentication.
   * @returns A promise that resolves when the sign-in is successful.
   */
  async signIn(returnUrl: string): Promise<void> {
    return signInWithRedirect({
      provider: { custom: environment.ssoProviderName },
      customState: returnUrl,
    });
  }

  /**
   * Check if the user is currently signed in.
   *
   * @returns A promise that resolves to true if the user is signed in, otherwise false.
   */
  async isSignedIn(): Promise<boolean> {
    // we assume we are logged in when Cognito is disabled locally
    if (!environment.enableAuthentication) {
      return true;
    }
    try {
      // checks both user & session
      const session = await fetchAuthSession();
      return !!session.tokens?.accessToken;
    } catch (e) {
      // cognito throws if there is no user
      return false;
    }
  }

  /**
   * Get the current user's information.
   *
   * @returns A promise that resolves to an object with user ID and email.
   */
  async currentUserInfo(): Promise<{ id: string; email: string }> {
    const [user, attributes] = await Promise.all([getCurrentUser(), fetchUserAttributes()]);

    if (!attributes.email) {
      throw new Error("Can't get email of current user");
    }

    return { id: user.userId, email: attributes.email };
  }

  /**
   * Refresh the current session's tokens.
   */
  async refresh(): Promise<void> {
    try {
      // The fetchAuthSession API automatically refreshes the user's session when the authentication tokens have expired
      // and a valid refreshToken is present.
      await fetchAuthSession();
    } catch (e) {
      // expected, cognito throws if we are not logged in
    }
  }

  /**
   * Sign out the currently authenticated user.
   */
  async signOut(): Promise<void> {
    await signOut();
  }

  getLastError(): string {
    return this.lastAuthError;
  }

  setLastError(error: string): void {
    this.lastAuthError = error;
  }

  /**
   * Get the authentication token for the current session.
   *
   * @returns The authentication token or null if there is no valid session.
   */
  async getToken(): Promise<string | null> {
    // cognito throws if there is not any session
    try {
      const session = await fetchAuthSession();
      if (!session.tokens?.idToken) {
        return null;
      }
      // AWS recommends against using the idToken for authorization purposes. The accessToken should be used instead.
      // But because of how our authorization is set up, we need to use the idToken.
      // See: https://ionity.atlassian.net/wiki/spaces/IONIT/pages/252248558/JWT+structure+alignment
      return `Bearer ${session.tokens?.idToken?.toString()}`;
    } catch (e) {
      return '';
    }
  }

  /**
   * Get the current ID JWT token of the user.
   *
   * @returns The ID JWT token or null if there is none or the session is not valid.
   */
  async getIdToken(): Promise<string | null> {
    try {
      const session = await fetchAuthSession();
      return session.tokens?.idToken?.toString() ?? null;
    } catch (e) {
      return null;
    }
  }

  /**
   * Clean up resources when the component is destroyed.
   */
  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}
